export interface DaftarNamaPenumpang {
  nama_penumpang: string
  no: number
  tanggal_lahir: string
}

export interface SyaratKetentuanModel {
  data: {
    [key: string]: string[]
    booking_fee: string[]
    down_payment: string[]
    full_payment: string[]
    full_payment_approval: string[]
    insert_passenger: string[]
    issued_ticket: string[]
    materialisasi: string[]
    order_reduction: string[]
    others: string[]
    ticket_terms: string[]
    time_limit: string[]
  }
  debug: {
    airline: string
    bookingToDeparture: string
    confirmDate: string
    departureDate: string
    inventoryType: string
    price: string
    supplier: string
  }
  error: boolean
  message: string
}

export interface OrderSummaryModel {
  data: {
    flight_detail: {
      departure_summary: [
        {
          date: string
          flight_number: string
        }
      ]
      returning_summary: [
        {
          date: string
          flight_number: string
        }
      ]
    }
    order_number: string
    payment: {
      booking_fee: number
      downpayment_1: number
      downpayment_2: number
      full_payment: number
    }
    pnr: string
    total_passenger: number
  }
  error: boolean
  message: string
}

export interface DaftarPenumpangGridDataModel {
  [key: string]: unknown
  no: number
  birthDate: string
  confirmRequested: boolean
  expireDate: string
  firstName: string
  issueDate: string
  lastName: string
  middleName: string
  nationality: string
  passengerId: string
  passportIssuingCity: string
  passportNumber: string
  title: string
}

export interface confirmModel {
  data: string
  error: boolean
  message: string
}

export enum DaftarNamaPenumpangActionType {
  GetDaftarNamaPenumpang = '⌘➝App➝legal-of-guarantee➝GetDaftarNamaPenumpang',
  GetSyaratKetentuan = '⌘➝App➝legal-of-guarantee➝GetSyaratKetentuan',
  GetOrderSummary = '⌘➝App➝legal-of-guarantee➝GetOrderSummary',
  GetPenumpang = '⌘➝App➝legal-of-guarantee➝GetPenumpang',
  GetConfirm = '⌘➝App➝legal-of-guarantee➝GetConfirm'
}

export interface DaftarNamaPenumpangModel {
  confirm: confirmModel[]
  daftarNamaPenumpang: DaftarNamaPenumpang[]
  OrderSummary: OrderSummaryModel
  penumpang: {
    data: DaftarPenumpangGridDataModel[]
    error: boolean
    message: string
  }
  syaratMain: SyaratKetentuanModel
}

export type DaftarNamaPenumpangAction = {
  data: {
    data: DaftarPenumpangGridDataModel[]
    error: boolean
    message: string
  }
  type: DaftarNamaPenumpangActionType.GetPenumpang
} | {
  data: confirmModel[]
  type: DaftarNamaPenumpangActionType.GetConfirm
} | {
  data: DaftarNamaPenumpang[]
  type: DaftarNamaPenumpangActionType.GetDaftarNamaPenumpang
} | {
  data: OrderSummaryModel
  type: DaftarNamaPenumpangActionType.GetOrderSummary
} | {
  data: SyaratKetentuanModel
  type: DaftarNamaPenumpangActionType.GetSyaratKetentuan
};
